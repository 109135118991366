.toggle {
    position: relative;
    box-sizing: border-box;
  }
  .toggle input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .toggle label {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .toggle label:before {
    content: '';
    width: 60px;
    height: 30px;
    background: #0d2637;
    position: relative;
    display: inline-block;
    border-radius: 46px;
    box-sizing: border-box;
    transition: 0.2s ease-in;
  }
  .toggle label:after {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    left: 4px;
    top: 4px;
    z-index: 2;
    background: #fff;
    box-sizing: border-box;
    transition: 0.2s ease-in;
  }
  .toggle input[type="checkbox"]:checked + label:before {
    background: #00c766;
  }
  .toggle input[type="checkbox"]:checked + label:after {
    left: 35px;
  }